function changeText(element: NodeList, text: number) {
  element.forEach(el => {
    ((el as HTMLElement).getAttribute("format") || "00") == "00" && text < 10
      ? (el.textContent = `0${text}`)
      : (el.textContent = String(text));
  });
}

function getTimeRemaining(date: number) {
  var t = date - Date.now();
  var seconds = Math.floor(t / 1000);
  var minutes = Math.floor(t / 1000 / 60);
  var hours = Math.floor(t / (1000 * 60 * 60));
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    total: t,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
}

document.querySelectorAll("[udesly-countdown]").forEach(el => {
  const wrappers = el.querySelectorAll('[countdown="wrapper"]');
  const wrappersCountdownEnd = el.querySelectorAll('[countdown="end"');
  const countdownDate = Date.parse(el.getAttribute("udesly-countdown") || "");

  if (isNaN(countdownDate)) {
    console.error(
      `${el.getAttribute(
        "udesly-countdown"
      )} is not a valid date, please use format ISO`
    );
    (el as HTMLElement).style.display = "none";
    return;
  }

  const days = el.querySelectorAll('[countdown="days"]');
  const hours = el.querySelectorAll('[countdown="hours"]');
  const minutes = el.querySelectorAll('[countdown="minutes"]');
  const seconds = el.querySelectorAll('[countdown="seconds"]');

  function animate() {
    const remainingTime = getTimeRemaining(countdownDate);

    if (days) {
      changeText(days, remainingTime.days);
    }
    if (hours) {
      changeText(
        hours,
        days.length ? remainingTime.hours % 24 : remainingTime.hours
      );
    }
    if (minutes) {
      changeText(
        minutes,
        hours.length ? remainingTime.minutes % 60 : remainingTime.minutes
      );
    }

    if (seconds) {
      changeText(
        seconds,
        minutes.length ? remainingTime.seconds % 60 : remainingTime.seconds
      );
    }

    if (remainingTime.total >= 0) {
      requestAnimationFrame(animate);
    } else {
      if (wrappers) {
        wrappers.forEach(wrapper => {
          wrapper.remove();
        });
      }

      if (wrappersCountdownEnd) {
        wrappersCountdownEnd.forEach(wrapper => {
          if (wrapper.getAttribute("countdown-end-class")) {
            wrapper.classList.add(
              wrapper.getAttribute("countdown-end-class") || ""
            );
          } else {
            (wrapper as HTMLElement).style.display = "inherit";
          }
        });
      }
    }
  }

  requestAnimationFrame(animate);
});
